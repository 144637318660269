<template>
  <IonApp>
    <IonSplitPane contentId="main-content">
      <ion-menu contentId="main-content" type="overlay">
        <ion-content>
          <ion-list id="inbox-list">
            <ion-list-header v-if="user && Object.keys(user).length != 0"><ion-avatar><img :src="user.avatar"></ion-avatar>&nbsp;&nbsp;&nbsp;{{user.nickname}}</ion-list-header>
			<ion-list-header v-else>Новый пользователь</ion-list-header>
            <ion-note></ion-note>
  
            <ion-menu-toggle auto-hide="false" v-for="(p, i) in appPages" :key="i">
              <ion-item @click="selectedIndex = i" router-direction="root" :router-link="p.url" lines="none" detail="false" class="hydrated" :class="{ selected: selectedIndex === i , 'notif-dot': p.notif}">
                <ion-icon slot="start" :ios="p.iosIcon" :md="p.mdIcon"></ion-icon>
                <ion-label>{{ p.title }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>
  
          <ion-list id="labels-list">
            <ion-list-header>Форумы</ion-list-header>
  
            <ion-item v-for="(p, i) in forums" lines="none" :key="i" :router-link="p.url">
              <ion-icon slot="start" :ios="bookmarkOutline" :md="bookmarkSharp"></ion-icon>
              <ion-label>{{ p.title }}</ion-label>
            </ion-item>
          </ion-list>
		<ion-list id="misc-list">
			<ion-item router-link="/app/settings" lines="none">
              <ion-icon slot="start" :ios="settingsOutline" :md="settingsSharp"></ion-icon>
              <ion-label>Настройки</ion-label>
            </ion-item>
          </ion-list>
        </ion-content>
      </ion-menu>
      <ion-router-outlet id="main-content" :data-notif="appPages[3].notif || appPages[1].notif" main></ion-router-outlet>
    </IonSplitPane>
  </IonApp>
</template>

<script lang="ts">
import { IonApp, IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonNote, IonRouterOutlet, IonSplitPane, IonAvatar } from '@ionic/vue';
import { defineComponent, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { archiveOutline, archiveSharp, bookmarkOutline, bookmarkSharp, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline,
	paperPlaneSharp, peopleOutline, peopleSharp, globeOutline, globeSharp, settingsOutline, settingsSharp } from 'ionicons/icons';
import axios from 'axios';

import useDataService from "./dataservice";

export default defineComponent({
  name: 'App',
  components: {
    IonApp, 
    IonContent, 
    IonIcon, 
    IonItem, 
    IonLabel, 
    IonList, 
    IonListHeader, 
    IonMenu, 
    IonMenuToggle, 
    IonNote, 
    IonRouterOutlet, 
    IonSplitPane,
	IonAvatar,
  },
  setup() {
    const selectedIndex = ref(0);
	const { user, token } = useDataService();
	
    const appPages = ref([
      {
        title: 'Мой дневник',
        url: '/blog/' + user.value.name,
        iosIcon: mailOutline,
        mdIcon: mailSharp
      },
      {
        title: 'Сообщения',
        url: '/im',
        iosIcon: paperPlaneOutline,
        mdIcon: paperPlaneSharp,
		notif: false
      },
      {
        title: 'Избранное',
        url: '/likes',
        iosIcon: heartOutline,
        mdIcon: heartSharp
      },
      {
        title: 'Обсуждения',
        url: '/discussions',
        iosIcon: archiveOutline,
        mdIcon: archiveSharp,
		notif: false
      },
      {
        title: 'Записи друзей',
        url: '/friends/posts',
        iosIcon: peopleOutline,
        mdIcon: peopleSharp
      },
      {
        title: 'Главная',
        url: '/feed',
        iosIcon: globeOutline,
        mdIcon: globeSharp
      }
    ]);
    const labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
	watch(user,() => {
		let username; 
		if(user && user.value && user.value.name.length !== 0){
			username = user.value.name;
		} else {
			username = null;
		}
		appPages.value[0] = {
			title: 'Мой дневник',
			url: '/blog/' + username,
			iosIcon: mailOutline,
			mdIcon: mailSharp
		};
	});
	
	const forums = [
		{
			title: "Просто общение",
			url: '/forum/discussion'
		},
		{
			title: "Флуд",
			url: '/forum/flood'
		}
	];
	
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      selectedIndex.value = appPages.value.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
    
    const route = useRoute();
	const updateInterval = 60 * 1000 //TODO: вынести в переменную настроек
	/*const user = ref({});
	
	storage.get({key: 'user'}).then(val => {
		user.value = JSON.parse(val.value || '{}');
	});*/
	
	const checkNotifications = () => {
		axios.get('https://beon.fun/api/v1/notifications/',{
		headers: {
			Authorization: 'Bearer ' + token.value,
		}})
		.then((response) => {
			if(appPages.value[1].notif != response.data.messages){
				appPages.value[1].notif = !!response.data.messages;
			}
			if(appPages.value[3].notif != response.data.discussions){
				appPages.value[3].notif = !!response.data.discussions;
			}
		}).finally(() => {setTimeout(checkNotifications, updateInterval)});
	};
	
	const setTmOut = setTimeout(checkNotifications, updateInterval);
	
    return { 
		selectedIndex,
		appPages, 
		labels,
		archiveOutline, 
		archiveSharp, 
		bookmarkOutline, 
		bookmarkSharp, 
		heartOutline, 
		heartSharp, 
		mailOutline, 
		mailSharp, 
		paperPlaneOutline, 
		paperPlaneSharp, 
		peopleOutline, 
		peopleSharp, 
		globeOutline, 
		globeSharp,
		settingsOutline,
		settingsSharp,
		isSelected: (url: string) => url === route.path ? 'selected' : '',
		user,
		forums
    }
  }
});
</script>

<style>
.post__author > img {
	max-width: unset!important;
}
.post {
	width: 100%;
	padding-bottom: 20px;
}
.post__title {
    font-weight: bold;
	font-size: 120%;
}
.post__date {
	color: #808080;
    font-size: 80%;
    margin-left: 1rem;
}
.post__body {
    display: flex;
}
.post__text {
    flex-grow: 1;
	word-break: break-word;
}
.post__author {
    text-align: center;
	margin-right: 1%;
	max-width: 120px;
}
.post__sign {
    font-size: 80%;
    color: #808080;
}
p.quotation {
	border-left: 3px solid #6666ff;
	padding-left: 5px;
	margin-left: 7px;
	margin-bottom: 0;
	padding-top: 0.3rem;
}
.send-button{
	width: 50px;
	height: 50px;
	border-radius: 25px;
}
.message-form {
	position: fixed;
	bottom: -20px;
	width: 100%;
	border: 1px solid blue;
}
#container {
	padding-bottom: 50px;
}
.selectable, .selectable >>> * {
	-webkit-user-select: text;
	-moz-user-select: text;
	-ms-user-select: text;
	user-select: text;
}
.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
}
.embed-responsive-16by9:before {
    padding-top: 56.25%;
}
.embed-responsive:before {
    display: block;
    content: "";
}
.embed-responsive iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.pin-container {
	position: relative;
	overflow: hidden;
	width: 98%!important;
	max-width: 600px;
	padding-top: 100%;
	padding-bottom: 82px;
}
.pin-container > iframe {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
}
@media(min-width: 600px){
	.pin-container {
		padding-top: 600px;
	}
}
.tiktok-container {
	position: relative;
	overflow: hidden;
	width: 98%!important;
	max-width: 290px;
	padding-top: 200%;
}
.tiktok-container > iframe {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
}
@media(min-width: 400px){
	.tiktok-container {
		padding-top: 600px;
	}
}
.instagram-container {
	position: relative;
	overflow: hidden;
	width: 98%!important;
	max-width: 320px;
	padding-top: 180%;
}
.instagram-container > iframe {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
}
@media(min-width: 400px){
	.instagram-container {
		padding-top: 530px;
	}
}
</style>

<style scoped>
ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list, ion-menu.md ion-list#labels-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;
  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;
  margin-bottom: 18px;
  color: #757575;
  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}

ion-item.notif-dot > ion-icon:before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: red;
    position: absolute;
    top: 0;
    left: 0;
}
ion-router-outlet[data-notif=true] >>> ion-menu-button:after {
	content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: red;
    position: absolute;
    top: 10px;
    left: 10px;
}
</style>