
import { IonApp, IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonNote, IonRouterOutlet, IonSplitPane, IonAvatar } from '@ionic/vue';
import { defineComponent, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { archiveOutline, archiveSharp, bookmarkOutline, bookmarkSharp, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline,
	paperPlaneSharp, peopleOutline, peopleSharp, globeOutline, globeSharp, settingsOutline, settingsSharp } from 'ionicons/icons';
import axios from 'axios';

import useDataService from "./dataservice";

export default defineComponent({
  name: 'App',
  components: {
    IonApp, 
    IonContent, 
    IonIcon, 
    IonItem, 
    IonLabel, 
    IonList, 
    IonListHeader, 
    IonMenu, 
    IonMenuToggle, 
    IonNote, 
    IonRouterOutlet, 
    IonSplitPane,
	IonAvatar,
  },
  setup() {
    const selectedIndex = ref(0);
	const { user, token } = useDataService();
	
    const appPages = ref([
      {
        title: 'Мой дневник',
        url: '/blog/' + user.value.name,
        iosIcon: mailOutline,
        mdIcon: mailSharp
      },
      {
        title: 'Сообщения',
        url: '/im',
        iosIcon: paperPlaneOutline,
        mdIcon: paperPlaneSharp,
		notif: false
      },
      {
        title: 'Избранное',
        url: '/likes',
        iosIcon: heartOutline,
        mdIcon: heartSharp
      },
      {
        title: 'Обсуждения',
        url: '/discussions',
        iosIcon: archiveOutline,
        mdIcon: archiveSharp,
		notif: false
      },
      {
        title: 'Записи друзей',
        url: '/friends/posts',
        iosIcon: peopleOutline,
        mdIcon: peopleSharp
      },
      {
        title: 'Главная',
        url: '/feed',
        iosIcon: globeOutline,
        mdIcon: globeSharp
      }
    ]);
    const labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
	watch(user,() => {
		let username; 
		if(user && user.value && user.value.name.length !== 0){
			username = user.value.name;
		} else {
			username = null;
		}
		appPages.value[0] = {
			title: 'Мой дневник',
			url: '/blog/' + username,
			iosIcon: mailOutline,
			mdIcon: mailSharp
		};
	});
	
	const forums = [
		{
			title: "Просто общение",
			url: '/forum/discussion'
		},
		{
			title: "Флуд",
			url: '/forum/flood'
		}
	];
	
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      selectedIndex.value = appPages.value.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
    
    const route = useRoute();
	const updateInterval = 60 * 1000 //TODO: вынести в переменную настроек
	/*const user = ref({});
	
	storage.get({key: 'user'}).then(val => {
		user.value = JSON.parse(val.value || '{}');
	});*/
	
	const checkNotifications = () => {
		axios.get('https://beon.fun/api/v1/notifications/',{
		headers: {
			Authorization: 'Bearer ' + token.value,
		}})
		.then((response) => {
			if(appPages.value[1].notif != response.data.messages){
				appPages.value[1].notif = !!response.data.messages;
			}
			if(appPages.value[3].notif != response.data.discussions){
				appPages.value[3].notif = !!response.data.discussions;
			}
		}).finally(() => {setTimeout(checkNotifications, updateInterval)});
	};
	
	const setTmOut = setTimeout(checkNotifications, updateInterval);
	
    return { 
		selectedIndex,
		appPages, 
		labels,
		archiveOutline, 
		archiveSharp, 
		bookmarkOutline, 
		bookmarkSharp, 
		heartOutline, 
		heartSharp, 
		mailOutline, 
		mailSharp, 
		paperPlaneOutline, 
		paperPlaneSharp, 
		peopleOutline, 
		peopleSharp, 
		globeOutline, 
		globeSharp,
		settingsOutline,
		settingsSharp,
		isSelected: (url: string) => url === route.path ? 'selected' : '',
		user,
		forums
    }
  }
});
