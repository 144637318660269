import { ref } from "vue";
import { Plugins } from '@capacitor/core';
import axios from 'axios';
const { Storage } = Plugins;

const user = ref<any>({});
const token = ref<any>({});

Storage.get({key: 'user'}).then(item => {
	user.value = JSON.parse(item.value || '{}')
});

Storage.get({key: 'token'}).then(item => {
	token.value = item.value;
});
export default function () {

	const updateUser = (item: any) => {
		user.value = JSON.parse(item);
	};
	
	const updateToken = (itm: any) => {
		token.value = itm;
	};
	
	const doLike = function(item: any, ltype = 'comment'){
		axios.post('https://beon.fun/api/v1/misc/like/',{
				type: ltype,
				id: item.id
			},{
				headers: {
					Authorization: 'Bearer ' + token.value,
				}
			}
		).then((response) => {
			if(response.data.type === 'like'){
				item.liked = [0];
			} else if(response.data.type === 'unlike') {
				item.liked.length = 0;
			}
		});
	}
	
	const getRichText = (txt: string, short = false) => {
		let text = txt.replace(/(\s|^):-\)(\s|$)/gm,'$1<img src="//beon.fun/i/smiles/smile.png">$2')
		.replace(/(\s|^):-\((\s|$)/gm,'$1<img src="//beon.fun/i/smiles/sad.png">$2')
		.replace(/(\s|^);-\)(\s|$)/gm,'$1<img src="//beon.fun/i/smiles/wink.png">$2')
		.replace(/(\s|^):-\*(\s|$)/gm,'$1<img src="//beon.fun/i/smiles/kiss.png">$2')
		.replace(/(\s|^):-D(\s|$)/gm,'$1<img src="//beon.fun/i/smiles/big-smile.png">$2')
		.replace(/(\s|^):-O(\s|$)/gm,'$1<img src="//beon.fun/i/smiles/surprised.png">$2')
		.replace(/(\s|^):-P(\s|$)/gm,'$1<img src="//beon.fun/i/smiles/tongue-sticking-out.png">$2')
		.replace(/(\s|^)X-\((\s|$)/gm,'$1<img src="//beon.fun/i/smiles/angry.png">$2')
		.replace(/(\s|^)]:-\)(\s|$)/gm,'$1<img src="//beon.fun/i/smiles/devil.png">$2')
		.replace(/(\s|^)O:-\)(\s|$)/gm,'$1<img src="//beon.fun/i/smiles/angel.png">$2')
		.replace(/(\s|^):'\((\s|$)/gm,'$1<img src="//beon.fun/i/smiles/cry.png">$2')
		.replace(/(\s|^):-\[(\s|$)/gm,'$1<img src="//beon.fun/i/smiles/upset.png">$2')
		.replace(/(\s|^):-\\(\s|$)/gm,'$1<img src="//beon.fun/i/smiles/confused.png">$2')
		.replace(/(\s|^):-\|(\s|$)/gm,'$1<img src="//beon.fun/i/smiles/undecided.png">$2')
		.replace(/(\s|^):-\?(\s|$)/gm,'$1<img src="//beon.fun/i/smiles/thinking.png">$2')
		.replace(/(\s|^);~\)(\s|$)/gm,'$1<img src="//beon.fun/i/smiles/cunning.png">$2')
		.replace(/(\s|^)\(:\|(\s|$)/gm,'$1<img src="//beon.fun/i/smiles/tired.png">$2')
		.replace(/(\s|^)8-}(\s|$)/gm,'$1<img src="//beon.fun/i/smiles/crazy.png">$2')
		.replace(/(\s|^):-\$(\s|$)/gm,'$1<img src="//beon.fun/i/smiles/shhh.png">$2')
		.replace(/(\s|^)8-\|(\s|$)/gm,'$1<img src="//beon.fun/i/smiles/shocked.png">$2')
		.replace(/(\s|^)B-\)(\s|$)/gm,'$1<img src="//beon.fun/i/smiles/sun-glasses.png">$2')
		.replace(/(\s|^):\^\)(\s|$)/gm,'$1<img src="//beon.fun/i/smiles/turn-red.png">$2')
		.replace(/(\s|^)=\^B(\s|$)/gm,'$1<img src="//beon.fun/i/smiles/thumbs-up.png">$2')
		.replace(/(\s|^)=,B(\s|$)/gm,'$1<img src="//beon.fun/i/smiles/thumbs-down.png">$2')
		.replace(/\[emoji-([0-9A-F]*)\]/gm,'<img src="//vk.com/images/emoji/$1.png" alt="emoji">')
		.replace(/\[klbk-([a-z0-9_-]*)\]/gm,'<img src="//beon.fun/i/smiles/kolobok/$1.gif" alt="kolobok-smile">')
		.replace(/\[stkr-([\d]*)\]/gm,'<img src="//beon.fun/i/stickers/$1.png">')
		.replace(/\[stkr-vk-([\d]*)\]/gm,"<img src='//beon.fun/i/vkstickers/$1/128.png'>")
		.replace(/\[test-([\d]*)\]/gm,"<a href='//beon.fun/tests/$1/'>Пройти тест</a>")
		.replace(/^&gt; (.*)(\s|$)/gm,'<p class="quotation">$1</p>')
		.replace(/(\s|^)(https?:\/\/([a-zA-Z0-9_-]+).beon.fun\/?)(\s|$)/gm,'$1<a href="/blog/$3">$2</a>$4')
		.replace(/(\s|^)(https?:\/\/([a-zA-Z0-9_-]+).beon.fun\/([\d]+)(-[a-zA-Z0-9_-]*)?\/?)(\s|$)/gm,'$1<a href="/blog/$3/post/$4">$2</a>$6')
		.replace(/(\s|^)(https?:\/\/[a-zA-Z0-9а-яА-ЯёЁ./=+&?%_#-]+)(\s|$)/gm,'$1<a href="$2" target="_blank">$2</a>$3')
		.replace(/\r\n/g, '<br>')
		.replace(/\[B\](.*?)\[\/B\]/gm,'<strong>$1</strong>')
		.replace(/\[I\](.*?)\[\/I\]/gm,'<i>$1</i>')
		.replace(/\[U\](.*?)\[\/U\]/gm,'<u>$1</u>')
		.replace(/\[S\](.*?)\[\/S\]/gm,'<s>$1</s>')
		.replace(/\[H\](.*?)\[\/H\]/gm,'<span style="font-size: 1.6rem">$1</span>')
		.replace(/\[OFF\](.*?)\[\/OFF\]/gm,'<span style="font-size: 0.7em">$1</span>')
		.replace(/\[CENTER\](.*?)\[\/CENTER\]/gm,'<p style="text-align: center">$1</p>')
		.replace(/\[RIGHT\](.*?)\[\/RIGHT\]/gm,'<p style="text-align: right">$1</p>')
		.replace(/\[JUSTIFY\](.*?)\[\/JUSTIFY\]/gm,'<p style="text-align: justify">$1</p>');
		if(!short){
			text = text.replace('[SPOILER]','').replace('[/SPOILER]','');
		} else {
			text = text.replace(/\[SPOILER\].*\[\/SPOILER\]/,"<b>Подробнее...</b>");
		}
		return text.replace(/\[img-(original|medium|small|large)-(none|left|right)-([^\s]*?)\]/gm,"<span class='imagewrapper' style='display:inline-block;max-width:410px;'><img src='$3' style='max-width:100%' class='imagefsme'></span>")
		.replace(/\[video-youtube-(.*?)\]/gm,'<span class="embed-responsive embed-responsive-16by9" allowfullscreen style="max-width:600px;display:inline-block;"><iframe width="420" height="315" src="https://www.youtube.com/embed/$1"></iframe></span>')
		.replace(/\[audio-soundcloud-track-([0-9]*)?\]/gm,'<span style="display:inline-block;width: 100%;max-width:600px;"><iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/$1&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe></span>')
		.replace(/\[audio-soundcloud-playlist-([0-9]*)?\]/gm,'<span style="display:inline-block;width: 100%;max-width:600px;"><iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/$1&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe></span>')
		.replace(/\[audio-yamusic-track-([0-9]*)-([0-9]*)?\]/gm,'<iframe frameborder="0" style="border:none;width:80%;height:180px;" width="100%" height="180" src="https://music.yandex.ru/iframe/#track/$1/$2"></iframe>')
		.replace(/\[audio-yamusic-album-([0-9]*)?\]/gm,'<iframe frameborder="0" style="border:none;width:80%;height:450px;" width="100%" height="450" src="https://music.yandex.ru/iframe/#album/$1"></iframe>')
		.replace(/\[audio-spotify-track-(.*)?\]/gm,'<span style="display:inline-block;width:100%;max-width:600px"><iframe src="https://open.spotify.com/embed/track/$1" width="100%" height="80" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe></span>')
		.replace(/\[audio-spotify-album-(.*)?\]/gm,'<span style="display:inline-block;width:100%;max-width:600px"><iframe src="https://open.spotify.com/embed/album/$1" width="100%" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe></span>')
		.replace(/\[audio-spotify-playlist-(.*)?\]/gm,'<span style="display:inline-block;width:100%;max-width:600px"><iframe src="https://open.spotify.com/embed/playlist/$1" width="100%" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe></span>')
		.replace(/\[audio-vocaroo-(.*)?\]/gm,'<span style="display:inline-block;max-width:300px;width:100%"><iframe width="100%" height="60" src="https://vocaroo.com/embed/$1" frameborder="0"></iframe></span>')
		.replace(/\[pin-([\d]*)\]/gm,'<span class="pin-container" style="display:inline-block;width: 100%;max-width:600px;"><iframe src="https://assets.pinterest.com/ext/embed.html?id=$1" width="100%" frameborder="0" scrolling="no"></iframe></span>')
		.replace(/\[inst-(.*)?\]/gm,'<span  class="instagram-container" style="display:inline-block;width: 100%;max-width:320px;"><iframe width="100%" height="440" src="https://instagram.com/p/$1/embed" frameborder="0"></iframe></span>')
		.replace(/\[video-tiktok-(.*)?\]/gm,'<span class="tiktok-container" style="width:98%;max-width:290px;display:inline-block;"><iframe src="https://www.tiktok.com/embed/$1?lang=ru-RU" style="width:100%; display: inline-block; height: 600px;" frameborder="0" scrolling="no"></iframe></span>')
	}

	return {
		user,
		updateUser,
		token,
		updateToken,
		getRichText,
		doLike
	};
}
