import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/login/'
  },
  {
    path: '/login',
    component: () => import ('../views/Login.vue')
  },
  {
    path: '/feed',
    component: () => import ('../views/Feed.vue')
  },
  {
    path: '/folder/:id',
    component: () => import ('../views/Folder.vue')
  },
  {
    path: '/blog/:blogslug/',
    component: () => import ('../views/Folder.vue')
  },
  {
    path: '/blog/:blogslug/new',
    component: () => import ('../views/PostNew.vue')
  },
  {
    path: '/blog/:blogslug/post/:id',
    component: () => import ('../views/Post.vue')
  },
  {
    path: '/user/:id',
    component: () => import ('../views/UserProfile.vue')
  },
  {
    path: '/forum/:name',
    component: () => import ('../views/Forum.vue')
  },
  {
    path: '/forum/:name/new',
    component: () => import ('../views/TopicNew.vue')
  },
  {
    path: '/topic/:id',
    component: () => import ('../views/Topic.vue')
  },
  {
    path: '/friends/posts',
    component: () => import ('../views/FriendsPosts.vue')
  },
  {
    path: '/im',
    component: () => import ('../views/Im.vue')
  },
  {
    path: '/im/:usr',
    component: () => import ('../views/Dialog.vue')
  },
  {
    path: '/chats/:cht',
    component: () => import ('../views/Chat.vue')
  },
  {
    path: '/likes',
    component: () => import ('../views/Likes.vue')
  },
  {
    path: '/discussions',
    component: () => import ('../views/Discussions.vue')
  },
  {
    path: '/app/settings',
    component: () => import ('../views/AppSettings.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
